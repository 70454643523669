<template>
  <td :class="classesList">
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    type: String,
  },
  setup(props){

    let classesList = [`tb-odr-${props.type}`]
    return {
      classesList,
    }
  },
}
</script>

<style scoped>

</style>
