<template>
  <tr class="tb-odr-item">
    <slot></slot>
  </tr>
</template>

<script>
export default {

}
</script>
