<template>
  <thead class="tb-odr-head">
  <tr class="tb-odr-item">
    <th class="tb-odr-info">
      <span class="tb-odr-id">Invoice ID</span>
      <span class="tb-odr-date d-none d-md-inline-block">Date</span>
    </th>
    <th class="tb-odr-amount">
      <span class="tb-odr-total">Amount</span>
      <span class="tb-odr-status d-none d-md-inline-block">Status</span>
    </th>
    <th class="tb-odr-action">&nbsp;</th>
  </tr>
  </thead>
</template>

<script>
export default {}
</script>
